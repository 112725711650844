import bodymovin from '../plugins/lottiePlugin';
import '../plugins/pluginInViewPort';

export default function lottie() {
	jQuery('.tab-content > .tab-content__slide').each( function () {
    const holder = jQuery(this);
		const opener = jQuery('.tabset__link');
		const lottieHolder = holder.find('.lottie');

		if (!lottieHolder.length) return;

		const path = lottieHolder.attr('data-src');

		const animationSkipForward = bodymovin.loadAnimation({
			container: lottieHolder[0],
			renderer: 'svg',
			loop: false,
			autoplay: false,
			path
		});

		opener.each( function () {
			jQuery(this).on('click', function(flag) {
				if (!flag) return;
				animationSkipForward.playSegments([0,70], true);
			});
		});

		if (holder.hasClass('tabset__active')) {
			animationSkipForward.playSegments([0,70], false);
		}
  });
}

export function initInViewport() {
	jQuery('.viewport-animation').each(function() {
		const holder = jQuery(this);
		const lottieHolder = holder.find('.lottie');

		if (!lottieHolder.length) return;

		const path = lottieHolder.attr('data-src');
		const animationSkipForward = bodymovin.loadAnimation({
			container: lottieHolder[0],
			renderer: 'svg',
			loop: false,
			autoplay: false,
			path
		});

		holder.on('in-viewport', function(e, flag) {
			if (!flag) return;
			animationSkipForward.playSegments([0,70], true);
		});

		holder.itemInViewport({
			visibleMode: 1
		});
	});

	jQuery('.section-animate').itemInViewport({
		activeClass: 'animated',
		visibleMode: 4
	});

	jQuery('.footer-animate').itemInViewport({
		activeClass: 'animated',
		visibleMode: 2
	});
}

