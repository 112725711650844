import '../plugins/openClosePlugin';
import '../plugins/responsiveHelper';

// open-close init
export function initOpenClose() {
	jQuery('.dropdown-holder').openClose({
		activeClass: 'dropdown--active',
		opener: '.dropdown__opener',
		slider: '.dropdown__slide',
		animSpeed: 400,
		event: 'hover',
		hideOnClickOutside: true,
		effect: 'slide'
	});
}

export default function initPreventClick() {
	ResponsiveHelper.addRange({
	'..767': {
		on: function() {
			jQuery('.dropdown__opener').one('click', function(e) {
				e.preventDefault();
				return false;    
			});
		},
		off: function() {
			jQuery('.dropdown__opener').one('click', function(e) {
				$('pre').append(e.type+'ed');  
			});
		}
	}
});
}
