export default function initScrollText() {
  jQuery('.scroll-text-wrap').each(function () {
    const $self = jQuery(this);
    const $decor = $self.find('.decor');
    const $scrollHolder = $self.find('.scroll-text');
    const $scrollList = $scrollHolder.find('.scroll');
    const $scrollItems = $scrollList.find('.gradiented');
    const changeDuration = 2500;
    const animateDuration = 500;

    let height = setHeight();
    cloneList();
    startAnimation();

    jQuery(window).on('resize orientationchange', () => {
      height = setHeight();
    });

    function cloneList() {
      $scrollList.clone().insertAfter($scrollList);
    }

    function setHeight() {
      const height = jQuery($scrollItems[0]).height();
      $scrollHolder.css('height', `${height}px`);
      return height;
    }

    function startAnimation() {
      const maxSteps = $scrollItems.length;
      let scrollStep = 0;
      let rotateStep = 0;

      setInterval(() => {
        $scrollList.animate(
          { 'margin-top': `${scrollStep * -height}px` },
          {
            duration: animateDuration,
            complete: () => {
              if (scrollStep === maxSteps) {
                $scrollList.css('margin-top', 0);
                scrollStep = 1;
              } else {
                scrollStep++;
              }

              if (rotateStep === 4) {
                $decor.css({
                  transform: 'rotate(0deg)',
                  transition: 'none',
                });
                rotateStep = 1;
              } else {
                rotateStep++;
              }
            },
            step: () => {
              $decor.css({
                transform: `rotate(${rotateStep * 90}deg)`,
                transition: `transform 0.5s ease-in-out`,
              });
            },
          }
        );
      }, changeDuration);
    }
  });
}
