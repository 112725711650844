import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';

gsap.registerPlugin(ScrollTrigger);

export default function initScrollTriger() {
  // $('section-inscroll').each(function() {
  //   $.fn.reverse = [].reverse;

    
  //   var targetT = $(this).attr('data-section-group')
  //   var invert = ':not(' + targetT + ')'
  //   var wrap = '<div class="section-scroll">'
  //   var breakpoints = $('.main > *'+invert)

  //   console.log(targetT)

  //   breakpoints.each(function(){
  //     $(this).nextUntil(invert).wrapAll( wrap );
  //   });

  //   breakpoints.first().prevUntil(invert).reverse().wrapAll( wrap );

  // });

  jQuery('.tabs-services').each(function () {
    const holder = jQuery(this)[0];
    const services = jQuery(holder).find('.tab-content')[0];

    ScrollTrigger.matchMedia({
      '(min-width: 768px)': function () {
        gsap.to(services, {
          scrollTrigger: {
            trigger: services,
            start: 'top 150px',
            endTrigger: holder,
            end: `bottom ${jQuery(services).height() +100}`,
            pin: true,
            pinSpacing: false,
            pinType: 'transform',
          },
        });
      },
      '(min-width: 1440px)': function () {
        gsap.to(services, {
          scrollTrigger: {
            trigger: services,
            start: 'top 280px',
            endTrigger: holder,
            end: `bottom ${jQuery(services).height() +90}`,
            pin: true,
            pinSpacing: false,
            pinType: 'transform',
          },
        });
      }
    });
  });

  // jQuery('.section-scroll').each(function () {
  //   var holder = jQuery(this)[0];

  //   ScrollTrigger.matchMedia({
  //     '(min-width: 768px)': function () {
  //       gsap.to(holder, {
  //         y: -150,
  //         scale: 0.9,
  //         '--opacity': 0.5,
  //         ease: 'power2.out',
  //         scrollTrigger: {
  //           trigger: holder,
  //           start: 'bottom bottom',
  //           end: 'bottom top',
  //           scrub: 1,
  //           pin: true,
  //           pinSpacing: false,
  //         },
  //       });
  //     },
  //   });
  // });
}
