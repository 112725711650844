import '../plugins/tabsPlugin';

// content tabs init
export function initTabs() {
	jQuery('.tabset').tabset({
		tabLinks: '.tabset__link',
		activeClass: 'tabset__active',
		attrib: 'data-tab',
		defaultTab: true
	});
}
