import '../plugins/anchorPlugin';
import '../plugins/responsiveHelper';

// initialize smooth anchor links
export function initAnchors() {
	var anchorInstance1;
	ResponsiveHelper.addRange({
		'..767': {
			on: function() {
				anchorInstance1 = new SmoothScroll({
					anchorLinks: 'a[href^="#"]:not([href="#"])',
          extraOffset: 50,
					wheelBehavior: 'none'
				});
			},
			off: function() {
				anchorInstance1.destroy();
			}
		}
	});

	new SmoothScroll({
		anchorLinks: 'a[href^="anchor-top"]',
		extraOffset: 0,
		wheelBehavior: 'none'
	});

	new SmoothScroll({
		anchorLinks: 'a[href^="#apply-section"]',
		extraOffset: 0,
		wheelBehavior: 'none'
	});
}
