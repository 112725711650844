import ready, { HTML } from './utils';
import { initAccordion } from './components/initAccordion';
import { initAnchors } from './components/initAnchor';
import initClick from './components/initClick';
import { initMediaElement } from './components/initMedia';
import { initOpenClose } from './components/initOpenClose';
import initPreventClick from './components/initOpenClose';
import { initStickyScrollBlock } from './components/initSticky';
import { initTabs } from './components/initTabs';
import { initMobileNav } from './components/mobileNav';
import lottie from './components/lottie';
import { initInViewport } from './components/lottie';
import initSlick from './components/slick';
// import initSliderMouse from './components/sliderMouse';
import initScrollTriger from './components/scrollTriger';
import initScrollText from './components/textScroll';
import vimeoPlayer from './components/videoPlay';
import initCardsFiltration from './components/cardsFiltration';

ready(() => {
  HTML.classList.add('is-loaded');
  HTML.classList.add('rivers-testing-now');
  initAccordion();
  initAnchors();
  initClick();
  initMediaElement();
  initInViewport();
  initOpenClose();
  initPreventClick();
  initMobileNav();
  initStickyScrollBlock();
  initTabs();
  lottie();
  initSlick();
  initScrollTriger();
  // initSliderMouse();
  initScrollText();
  vimeoPlayer();
  initCardsFiltration();
});
