import '../../../../../../../node_modules/slick-carousel/slick/slick';

export default function initSlick () {
  jQuery('.drag-slider')
    .on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
      var i = (currentSlide ? currentSlide : 0) + 1;
      $('.pagination-count--primary').text('0' + i);
      $('.pagination-all--primary').text(' - 0' + slick.slideCount);
    })
    .slick({
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      prevArrow: '<button class="slick-prev"><span class="icon-arrow-one-reverse"></span></button>',
      nextArrow: '<button class="slick-next"><span class="icon-arrow"></span></button>',
      variableWidth: true,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            variableWidth: false
          }
        },
      ]
    });

  jQuery('.quote-slider')
    .on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
			var i = (currentSlide ? currentSlide : 0) + 1;
  		$('.pagination-count--secondary').text('0' + i);
			$('.pagination-all--secondary').text(' - 0' + slick.slideCount);
		})
    .slick({
      slidesToScroll: 1,
			slidesToShow: 1,
      rows: true,
			prevArrow: '<button class="slick-prev"><span class="icon-arrow-one-reverse"></span></button>',
			nextArrow: '<button class="slick-next"><span class="icon-arrow"></span></button>',
			infinite: true,
			speed: 400
    })
}
