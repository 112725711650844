import '../plugins/accordionPlugin';

// accordion menu init
export function initAccordion() {
	jQuery('.accordion').slideAccordion({
		opener: '.accordion__opener',
		slider: '.accordion__slide',
		animSpeed: 300,
    activeClass: 'accordion-active'
	});
}