import '../plugins/mobileNavPlugin';
import '../plugins/responsiveHelper';

// mobile menu init
export function initMobileNav() {
	ResponsiveHelper.addRange({
		'..768': {
			on: function() {
				jQuery('body').mobileNav({
					menuActiveClass: 'nav-active',
					menuOpener: '.header-menu__opener'
				});
			},
			off: function() {
				jQuery('body').mobileNav('destroy');
			}
		}
	});
}
