import '../plugins/froogaloop';

export default function vimeoPlayer() {
  var iframe = $('#vimeo-video')[0];
  var player = $f(iframe);
  var holder = $('#video-wrap');

  var playButton = $('#play-btn');
  var videoOverlay = $('#video-overlay');

  player.addEvent('ready', function() {
    player.addEvent('playProgress', onPlayProgress);
  });

  playButton.bind('click', function() {
    player.api('play');
    holder.addClass('video-active');
  });
 

  videoOverlay.bind('click', function() {
    player.api('play');
    holder.addClass('video-active');
  });
}
