import { initLoadMore } from './loadMore';
// import { ScrollTrigger } from 'gsap/ScrollTrigger.js';
import { ev } from '../utils/index';

export default function initCardsFiltration() {
  const sections = jQuery('.cards-section');
  const activeClass = 'is-checked';

  sections.each(function () {
    const section = jQuery(this);
    const cardsWrapper = section.find('.cards-wrapper');
    const categoryLinks = section.find('.tagcloud span');
    const activeFilter = section.find('.tagcloud span.is-checked');

    filterCards({
      categoryLinks,
      activeFilter: activeFilter.length ? jQuery(activeFilter[0]).data('filter') : jQuery(categoryLinks[0]).data('filter'),
      activeClass,
      cardsWrapper,
    });

    categoryLinks.each(function () {
      jQuery(this).on('click', function (e) {
        e.preventDefault();
        const activeFilter = jQuery(e.target).data('filter');

        filterCards({
          categoryLinks,
          activeFilter,
          activeClass,
          cardsWrapper,
        });
      });
    });

    // jQuery(window).on('ContentLoader/loaded.ScrollLoader', function() {
    //   ScrollTrigger.refresh();
    // })
  });

  function filterCards({ categoryLinks, activeFilter, activeClass, cardsWrapper }) {
    jQuery(categoryLinks).each(function () {
      const filter = jQuery(this).data('filter');
      const url = jQuery(this).data('link');

      if (filter === activeFilter) {
        jQuery(this).addClass(activeClass);
        renderCards({ url, cardsWrapper });
      } else {
        jQuery(this).removeClass(activeClass);
      }
    });
  }

  function renderCards({ url, cardsWrapper }) {
    const instance = initLoadMore();
    instance.data('ContentLoader').destroy();

    jQuery
      .get({
        url,
        cache: true,
        dataType: 'text',
      })
      .then(function (result) {
        cardsWrapper.html(result);

        initLoadMore();
        // ScrollTrigger.refresh();
      })
      .catch(function (err) {
        console.error(err);
      })
      .then(function() {
        ev('cardsFiltration.loaded', {});
      })
  }
}
